* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Ubuntu', sans-serif;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --bg-primary: #f5f5f5;
  --bg-gray: #828282;
  --bg-green: #32D83D;
  --bg-dark-green: #186A1E;
  
  --color-green-lemon: #D5FD30;
  
  --title-primary-blue: #0576AC;
  --title-primary-dark-blue: #1B2B33;
}

.app {
  display: flex;
}

.mainContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.App {
  font-family: Arial, sans-serif;
  text-align: center;
  margin-top: 50px;
}

.question-container {
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  margin: 0 auto;
}

.question-container h3 {
  margin-top: 0;
}

.question-container label {
  display: block;
  margin: 10px 0;
}

.question-container button {
  margin-top: 20px;
  padding: 10px 20px;
  cursor: pointer;
}