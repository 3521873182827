.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid #bdc3c7;
  }
  
  .user {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  .user img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-left: 10px;
  }
  