.container{
  display: flex;
  height: 100vh;
}

.mainContent {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.dashboard {
  padding: 1rem;
  flex: 0.5 0.5;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

.myChartContainer, 
.chartContainer, 
.mapCollaborators, 
.statsContainer {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 1350px) {

  .dashboard {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(4, 1fr);
  }
  
}

.statCard {
  display: flex;
  background-color: white;
  padding: 20px;
  align-items: center;
  justify-content: space-between;
}

.content {
  display: flex;
  gap: 1rem;
}

.statsTexts {
  display: flex;
  flex-direction: column;
}

.statsTexts p, h3 {
  margin: 0;
}

.statsTexts p {
  color: var(--title-primary-blue);
}

.statsTexts span {
  color: var(--bg-green);
}

.chartIndicator {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chartIndicator span {
  color: var(--title-primary-blue);
  font-style: italic;
}

.reportMapCollaborators {
  display: flex;
  justify-content: space-around;
} 
.statsTitle {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
}

.statsCards {
  display: flex;
  flex-direction: column;
}

