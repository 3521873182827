.sidebar {
  width: 250px;
  background-color: #fff;
  color: #1B2B33;
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 1rem;
}

.logo {
  display: flex;
  justify-content: center;
  padding: 1rem;
}

.moneySaved {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #0576AC;
  padding: 1rem;
  border-radius: 1rem;
}

.moneySaved p {
  color: #fff;
  font-size: 2rem;
  font-weight: 700;
  border-radius: 1rem;
  margin: 0;
}

.moneyText {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.moneyText p {
  font-size: 1rem;
}

.menu {
  list-style: none;
  padding: 0;
}

.menu li {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  font-weight: 400;
}

.menu li:hover {
  font-weight: 700;
  color: #fff;
  background-color: #0576AC;
  border-radius: 1rem;
}

.menu li a {
  display: flex;
  color: inherit;
  text-decoration: none;
  gap: 0.5rem;
  align-items: center;
}