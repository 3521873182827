.container {
  display: flex;
  padding: 1rem 3rem;
  gap: 1rem;
  flex-direction: column;
  justify-content: center;
}

.logo {
  width: 16rem;
  margin-left: -1.5rem;
}

.title {
  align-items: center;
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.title h2 {
  margin: 0;
}

.cards {
  display: flex;
  justify-content: center;
  gap: 5rem;
  margin-top: 1rem;
}

.cards a {
  text-decoration: none;
}

.card {
  background-color: #172B3A;
  border-radius: 0 8rem;
  color: #fff;
  text-align: center;
  height: 30rem;
}

.card span {
  display: block;
  font-weight: 600;
  font-size: 30px;
  margin-top: 5rem;
}

.icon {
  height: 15rem;
  width: 16rem;
  border-radius: 0 7.9rem;
  margin-top: -0.5rem;
}

.card:hover {
  transform: scale(1.02);
}

@media screen and (max-width: 1000px) {
  .icon {
    width: 14rem;
  }

  .cards {
    gap: 2rem;
  }

  .card span {
    font-size: 20px;
  }
}

@media screen and (max-width: 830px) {
  .icon {
    width: 12rem;
  }

  .cards {
    gap: 1rem;
  }

  .card {
    height: 25rem;
    border-radius: 0 6rem;
  }

  .card .icon {
    border-radius: 0 5.9rem;
  }

  .card span {
    margin-top: 2rem;
  }

}

@media screen and (max-width: 700px) {
  .icon {
    width: 10rem;
    height: 10rem;
  }

  .cards {
    gap: 1rem;
  }

  .card {
    height: 20rem;
    border-radius: 0 4.9rem;
  }

  .card .icon {
    border-radius: 0 4.9rem;
  }

  .card span {
    font-size: 15px;
  }

  .title {
    margin-bottom: 0;
  }

  .title h1 {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 600px) {
  .icon {
    width: 8rem;
    height: 8rem;
  }

  .cards {
    gap: 1rem;
  }

  .card {
    height: 15rem;
    border-radius: 0 3.9rem;
  }

  .card .icon {
    border-radius: 0 3.9rem;
  }

  .card span {
    margin-top: 2rem;
  }

  .title {
    margin-bottom: 0;
  }

  .title h1 {
    font-size: 1.2rem;
  }

  .socialIcon,
  .governanceIcon,
  .environmentIcon {
    background-size: 3rem;
  }
}

@media screen and (max-width: 500px) {
  .logo {
    width: 12rem;
  }

  .title {
    margin-bottom: 0;
  }

  .card {
    height: 13rem;
    border-radius: 0 3rem;
  }

  .card .icon {
    border-radius: 0 3rem;
  }

  .icon {
    width: 7rem;
    height: 7rem;

  }

  .card span {
    font-size: 12px;
    margin-top: 1.5rem;
  }
}

@media screen and (max-width: 400px) {

  .container {
    padding: 1rem;
  }

  .logo {
    margin-left: -1.2rem;
  }

  .title {
    margin-bottom: 0;
  }

  .title h1 {
    font-size: 1.2rem;
  }

  .icon {
    width: 6rem;
    height: 6rem;
  }

  .cards {
    gap: 1rem;
  }

  .card {
    height: 13rem;
  }

  .card span {
    margin-top: 2rem;
  }

  .socialIcon,
  .governanceIcon,
  .environmentIcon {
    background-size: 3rem;
  }
}

.socialIcon {
  background-image: url("../../assets/social-icon.png");
  background-repeat: no-repeat;
  background-position: center;
  background-color: #00B2A9;
}

.environmentIcon {
  background-image: url("../../assets/environment-icon.png");
  background-repeat: no-repeat;
  background-position: center;
  background-color: #B5F22A;
}

.governanceIcon {
  background-image: url("../../assets/governance-icon.png");
  background-repeat: no-repeat;
  background-position: center;
  background-color: #FDD835;
}