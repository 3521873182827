.mainContainer {
    height: 100vh;
    width: 100vw;
    background-image: url("../../assets/bgImgSignIn.png");
    ;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    padding-right: 10rem;
}

@media screen and (max-width: 768px) {
    .mainContainer {
        padding-right: 0;
        justify-content: center;
    }

}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    padding: 1rem;
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 1rem;
    width: 450px;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    width: 100%;
}

.inputs {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1.5rem;
}

.inputContainer {
    position: relative;
    width: 100%;
}

.inputField {
    width: 100%;
    height: 2.5rem;
    padding: 0 1rem;
    font-size: 1rem;
    border: none;
    border-radius: 2rem;
    outline: none;
    background-color: var(--bg-primary);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

}

.inputField:focus {
    border-color: var(--bg-primary-dark-blue);
}

.inputField:focus+.inputLabel,
.inputField:not(:placeholder-shown)+.inputLabel {
    transform: translateY(-35px);
    font-size: 12px;
    color: var(--bg-gray);
}

.inputLabel {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.2s ease-in-out;
    pointer-events: none;
    color: var(--bg-gray);
    font-size: 12px;
    margin-left: 1rem;
}

button {
    cursor: pointer;
    width: 12rem;
    height: 3rem;
    border-radius: 2rem;
    border: none;
    background-color: #0576AC;
    color: #fff;
    font-weight: 500;
    font-size: 1.5rem;
}

.forgotPassword {
    font-weight: 600;
    font-size: 12px;
    color: var(--textColor-red2);
}

.lineLogin {
    width: 100%;
    height: 1px;
    background-color: var(--bg-gray);
    margin: 20px 0;
}

.btnGoogle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 2.5rem;
    border-radius: 2rem;
    border: none;
    background-color: var(--bg-primary);
    font-weight: 400;
    font-size: 1rem;
    cursor: pointer;
    color: #000;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.loginOrLine {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 1rem;
}

.line {
    flex-grow: 1;
    height: 1px;
    background-color: var(--bg-gray);
}

.textOr {
    font-size: 1rem;
    color: var(--textColor-red2);
}

.textLoginSocial {
    font-weight: 500;
    font-size: 1rem;
    text-align: center;
}

.iconGoogle {
    position: relative;
    right: 4rem;
}